import React from "react"
import Layout from "../components/layout/Layout"
import styles from "./project-list-template.module.css"
import Title from "../components/Title/Title"
import Portfolio from "../components/Portfolio/Portfolio"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

const ProjectListTemplate = (props) => {
  const { currentPage, numProjectPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numProjectPages
  const prevPage = currentPage - 1 === 1 ? `/portfolio` : `/portfolio/${currentPage - 1}`
  const nextPage = `/portfolio/${currentPage + 1}`
  const { data } = props

  return (
    <Layout>
      <SEO title='Portfolio' description='Nolan James Portfolio Projects'/>
      <section className={styles.portfolio}>
        <Title title='Latest' subtitle='Projects'/>
        <div className={styles.center}>
          {data.projects.edges.map(({ node }) => {
            return <Portfolio key={node.id} project={node}/>
          })}
        </div>
        <section className={styles.links}>
          {
            !isFirst &&
            <AniLink fade to={prevPage} className={styles.link}>
              Prev
            </AniLink>
          }
          {Array.from({ length: numProjectPages }, (_, i) => {
            return <AniLink key={i} fade to={`/portfolio/${i === 0 ? "" : i + 1}`}
                            className={i + 1 === currentPage ? `${styles.link} ${styles.active}` : `${styles.link}`}>
              {i + 1}
            </AniLink>
          })}
          {
            !isLast &&
            <AniLink fade to={nextPage} className={styles.link}>
              Next
            </AniLink>
          }
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query getProjects($skip: Int!, $limit: Int!) {
        projects: allContentfulPortfolio(skip: $skip, limit: $limit, sort:{fields: createdAt, order: DESC}) {
            edges {
                node {
                    id
                    title
                    slug
                    category {
                        title
                    }
                    image {
                        fluid {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                    description {
                        description
                    }
                    url
                    type {
                        title
                    }

                }
            }
        }
    }

`

export default ProjectListTemplate
